<template>
    <div>
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/index' }">店铺选品</el-breadcrumb-item>
            <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-title">商品管理</div>
        <div class="goodsPurchase-container">
            <div class="header-btn flex justify-end btn-center-group">
                <el-tag :type="eltagValue" v-if="isCompetiton" style="margin-right: 10px;">结束优化标题剩余次数：<b style="font-weight: bold; font-size: 16px;">{{leftCount}}</b></el-tag>
                <el-button class="btn-red-line" @click="endOptimization" :disabled="leftCount === 0">结束优化标题</el-button>
                <el-button class="btn-red" @click="addNewGoods">添加新商品</el-button>
            </div>
            <el-table :data="goodsList" border style="width: 100%; margin-top: 20px" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                      :cell-style="{fontSize: '14px',color: '#333'}">
                <el-table-column label="商品信息" min-width="200">
                    <template slot-scope="scope">
                        <div  class="goods-wrapper">
                            <p class="category">所属类目：{{ scope.row.good_type_name }}</p>
                            <div class="goods-box">
                                <div class="goods-cover" v-if="scope.row.xuchun_no1 === 0">
                                    <img :src="scope.row.image ? scope.row.image : require('../../../../assets/images/user_img.png')" alt="">
                                </div>
                                <router-link v-else :to="{path: '/student/operationpromotion/goodsmanage/detail', query: {id: scope.row.id}}"
                                             class="goods-cover" target="_blank">
                                    <img :src="scope.row.image ? scope.row.image : require('../../../../assets/images/user_img.png')" alt="">
                                </router-link>
                                <div class="goods-right">
                                    <div class="text-overflow-2" v-if="scope.row.xuchun_no1 === 0" :title="scope.row.name">{{ scope.row.name }}</div>
                                    <router-link  v-else :to="{path: '/student/operationpromotion/goodsmanage/detail', query: {id: scope.row.id}}"
                                                 class="text-overflow-2 goods-title" target="_blank" :title="scope.row.name">
                                        {{ scope.row.name }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="left" label="商品原始名" min-width="150">
                    <template slot-scope="scope">
                        <div class="text-overflow-2" :title="scope.row.brand_type_name + scope.row.parent_name + scope.row.old_name">{{scope.row.brand_type_name + scope.row.parent_name + scope.row.old_name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="marketvalue" align="center" label="市场价"></el-table-column>
                <el-table-column prop="number" align="center" label="总库存"></el-table-column>
                <el-table-column prop="click" align="center" label="点击率">
                    <template slot-scope="scope">
                        {{scope.row.click}}%
                    </template>
                </el-table-column>
                <el-table-column prop="conversion" align="center" label="转化率">
                    <template slot-scope="scope">
                        {{scope.row.conversion}}%
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" align="center" label="上架时间" width="160px"></el-table-column>
                <el-table-column align="center" label="修改时间" width="160px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.update_time === 0">暂无修改</span>
                        <span v-else>{{scope.row.update_time}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-link :underline="false" class="link-blue-light" @click="seeAttr(scope.row)">查看商品属性</el-link>
                        <el-link :underline="false" class="link-blue-light" @click="editBtn(scope.row)">优化商品标题</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="operation-pagination" style="margin: 20px 0 0;"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="pagesCurrentChange">
            </el-pagination>

            <el-dialog title="修改商品" :visible.sync="addDialog" width="520px" @close="resetForm()" :close-on-click-modal="false">
                <el-form :model="addForm" ref="addForm" :rules="addRules" label-position="right" label-width="100px">
                    <el-form-item label="优化标题" prop="name">
                        <el-input type="text" v-model="addForm.name" placeholder="1-50字" maxlength="50" show-word-limit class="title-input"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="medium" @click="addDialog = false">取 消</el-button>
                    <el-button size="medium" class="btn-red" @click="addToForm('addForm')">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <el-dialog title="商品信息" :visible.sync="goodInfoDialog" width="30%" custom-class="goodInfo-dialog">
            <div class="goodInfo-content" v-if="goodDetail">
                <div class="info-item">
                    <div class="item-title">标题</div>
                    <div class="item-content">{{goodDetail.name}}</div>
                </div>
                <div class="info-item">
                    <div class="item-title">属性</div>
                    <div class="item-content">
                        <div class="attr-list">
                            <div class="item" v-for="(attrItem, attrIndex) in goodDetail.goodInfo" :key="`attrList_${attrIndex}`">
                                {{attrItem.name}}: {{attrItem.data}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {
                goodsList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                // S 添加弹窗
                addDialog: false,
                addForm: {
                    id: '',
                    name: '',
                },
                addRules: {
                    name: [
                        { required: true, message: '请输入标题', trigger: 'blur' },
                        { min: 1, max: 50, message: '长度在 1 到 50', trigger: 'blur' },
                    ],
                },
                currentRow: null,
                // E 添加弹窗
                goodInfoDialog: false,
                goodDetail: null,
                leftCount: null,
            }
        },
        mounted() {
            this.getGoodsList()
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            },
            isCompetiton() {
                return this.$common.isCompetion() && this.leftCount !== null;
            },
            eltagValue() {
                if (this.leftCount > 0) {
                    return 'success';
                } else {
                    return 'info';
                }
            }

        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取商品列表
            getGoodsList() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                }
                this.$http.axiosGetBy(this.$api.storeSelectionAddGoods, params, (res) => {
                    if (res.code === 200) {
                        this.goodsList = res.data.data
                        this.listPages.total = res.data.total
                        if (Number(this.$lockr.get('competition_type')) === 1) {
                            let menus = this.menus;
                            if (res.data.count !== undefined) {
                                this.leftCount = Number(res.data.count) ? Number(res.data.count) : 0;
                            }
                            this.$lockr.set('goods_count', this.leftCount);
                            let currentStatus = menus[0].children[4].status;
                            if (Number(currentStatus) === 0) {
                                this.operation_updateStatus(0, 4, 2);
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getGoodsList()
            },
            // 结束优化标题
            endOptimization() {
                this.$confirm('是否确定要结束优化标题', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-red',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosGet(this.$api.storeSelectionEnd, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getGoodsList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消结束优化标题')
                });
            },
            // 添加新商品
            addNewGoods() {
                this.$router.push('/student/operationpromotion/index')
            },
            // 查看商品属性
            seeAttr(row) {
                this.$http.axiosGetBy(this.$api.storeSelectionAddGoods, {id: row.id}, (res) => {
                    if (res.code === 200) {
                        this.goodDetail = res.data.good_info
                        this.goodInfoDialog = true
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 修改商品
            editBtn(row) {
                this.addDialog = true
                // this.currentRow = JSON.parse(JSON.stringify(row))
                this.addForm.id = row.id
                this.addForm.name = row.name
            },
            // 添加弹窗的确定
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // this.addForm = {...this.addForm, ...this.currentRow}
                        let formData = new FormData();
                        formData.append('id', this.addForm.id)
                        formData.append('name', this.addForm.name)
                        this.$http.axiosPost(this.$api.storeSelectionUpdate, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.msg)
                                this.addDialog = false
                                this.getGoodsList()
                                this.resetForm()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 重置表单
            resetForm() {
                this.addForm = {
                    id: '',
                    name: '',
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/ p {
        margin: 0;
    }
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 189px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .btn-center-group {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .goods-wrapper {
        .category {
            line-height: 1;
            margin-bottom: 10px;
        }
        .goods-box {
            display: flex;
            .goods-cover {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border: 1px solid #eee;
                box-sizing: border-box;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-right {
                width: 1%;
                flex: 1;
                .goods-title {
                    color: #333;
                    line-height: 18px;
                    transition: all .3s;
                    &:hover {
                        color: #FD4446;
                    }
                }

            }
        }
    }
    /deep/ .goodInfo-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #fd4446;
        }
        .el-dialog__title {
            color: #fd4446;
        }
        .el-dialog__body {
            padding: 0 0 30px;
        }
        .goodInfo-content {
            .info-item {
                .item-title {
                    background: #eee;
                    line-height: 1;
                    height: 60px;
                    font-size: 18px;
                    font-weight: 500;
                    padding-left: 36px;
                    color: #333;
                    position: relative;
                    display: flex;
                    align-items: center;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 20px;
                        top: 20px;
                        height: 18px;
                        width: 3px;
                        background: #333;
                    }
                }
                .item-content {
                    padding: 20px;
                    .attr-list {
                        .item {
                            margin-top: 10px;
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .title-input {
        /deep/ .el-input__inner {
            padding-right: 50px;
        }
    }
</style>